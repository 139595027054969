tbody .allocated--free-resource {
	--value: 1px solid #7C7C7C;
	border-left: var(--value);
	border-right: var(--value);
}

tbody .day--free-resource {
	border: 1px solid #F7F7F7;
	border-left-color: transparent;
}

thead .day-weekend--free-resource::before {
	background-color: rgb(24, 144, 255)!important;
}

thead .day--free-resource:has(+ .day-weekend--free-resource)::before {
	background-color: rgb(24, 144, 255)!important;
}