@import "antd/dist/antd.css";

* {
	scrollbar-color: #ccc white;
	scrollbar-width: thin;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #f0f2f5;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* Reset css */
p {
	margin-bottom: 0;
}

/* Fix antd css */

.ant-modal-content .anticon.anticon-close.ant-modal-close-icon {
	height: 100%;
}

/* scroll bar */
::-webkit-scrollbar {
	width: 0.5rem;
	height: 0.5rem;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #ccc;
	border: 1px solid #ccc;
}

::-webkit-scrollbar-thumb:hover {
	background: white;
}
